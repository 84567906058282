<template>
  <!-- 课中 -->
  <div class="container">
    <div class="row clearfix displayFlex" id="clearfix">
      <!-- <Nav_class></Nav_class> -->
      <div
        class="column class_study height85vh"
        style="margin: 0 15px;flex: 1 1 auto;"
      >
        <div class="class_study_con">
          <div class="class_study_con_base">
            活动单列表
          </div>
        </div>
        <div>
          <jobList ref="jobList"></jobList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav_class from '../../components/Nav_class';
import jobList from '../JobList/index.vue';
export default {
  name: 'Class',
  components: {
    jobList,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped="scoped">
/deep/ .el-progress-bar {
  width: 80%;
}
</style>
